import React from "react";
import { Link } from "gatsby";
// import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import blogSer1 from "../../../../images/blog_ser_icon1.svg";
import blogSer2 from "../../../../images/blog_ser_icon2.svg";
import blogSer3 from "../../../../images/blog_ser_icon3.svg";
import blogSer4 from "../../../../images/blog_ser_icon4.svg";
import blogSer5 from "../../../../images/blog_ser_icon5.svg";
import blogSer6 from "../../../../images/blog_ser_icon6.svg";
import blogSer7 from "../../../../images/blog_ser_icon7.svg";
import blogSer8 from "../../../../images/blog_ser_icon8.svg";
import { AmpXImage } from "../../../customs/ampImage";

const ServicesList = ({ isAMP, serviceData }) => {
  return (
    <div className="serviceContainer">
      <h6>{serviceData.ourServices}</h6>
      <p>{serviceData.description}</p>
      <div className="serviceWrapper">
        <Link to="/cloud-consulting-services">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer1}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.cloudConsulting}</h5>
        </Link>
        <Link to="/cloud-migration-services">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer2}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.cloudMigration}</h5>
        </Link>
        <Link to="/cloud-optimization-services">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer3}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.cloudOptimization}</h5>
        </Link>
        <Link to="/managed-cloud-services">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer4}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.managedCloud}</h5>
        </Link>
        <Link to="/cloud-operations/">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer5}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.cloudOperations}</h5>
        </Link>
        <Link to="/enterprise-applications">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer6}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.enterprise}</h5>
        </Link>
        <Link to="/security-as-a-service">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer7}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.security}</h5>
        </Link>
        <Link to="/disaster-recovery-as-a-service">
          <div className="icon">
            <AmpXImage
              isAMP={isAMP}
              src={blogSer8}
              alt="blog_ser_icon1"
              width={isAMP ? 51 : "100%"}
              height={isAMP ? 48 : "100%"}
            />
          </div>
          <h5>{serviceData.disaster}</h5>
        </Link>
      </div>
    </div>
  );
};
export default ServicesList;
