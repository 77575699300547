import React, { memo } from "react";

export const Guide = memo(({ unsure }) => {
  return (
    <div className="guideContainer">
      <div className="guideTitle">{unsure.title}</div>
      <div className="guideDescription">{unsure.description}</div>
      <a className="guideBtn" href="#contact_form">
        {unsure.button}
      </a>
    </div>
  );
});
