import React, { memo } from "react";

export const BlogTopics = memo(({ topics, title }) => {
  return (
    <div className="topicsContainer">
      <div className="topicsTitle">{title}</div>
      <div className="topicsWrapper">
        {topics.map((topic) => (
          <div id={topic} className="topicItem">
            {topic}
          </div>
        ))}
      </div>
    </div>
  );
});
