import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import attachIcon from "../../../../images/attach-file.svg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ClipLoader from "react-spinners/ClipLoader";
import AuthorImg from "../../../../images/blog_author_img.png";

const ContactFormInBlog = ({ data, locale, layoutData }) => {
  let Swal;
  if (typeof window !== undefined) {
    Swal = require("sweetalert2");
  }
  const [marketingConsent, setMarketingConsent] = useState("No");
  const [entries, setEntries] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    message: "",
    phone: "",
    contactMethod: "contact",
  });
  const [nameErr, setNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [numberErr, setNumberErr] = useState();
  const [orgErr, setOrgErr] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [imgUpload, setImgUpload] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [country, setCountry] = useState("us");
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useLayoutEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  let count = 0;
  const onChangeFile = (e) => {
    var file = e.target.files[0];
    setIsFilePicked(true);
    setImgUpload(file);
  };

  const deleteUploadedFile = () => {
    setIsFilePicked(false);
    setImgUpload("");
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const processReCaptcha = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return null;
      }

      const token = await executeRecaptcha("submitForm");
      return token || null;
    } catch (e) {
      return null;
    }
  }, [executeRecaptcha]);

  const handleLinkedInCapture = useCallback(async () => {
    try {
      window.lintrk("track", { conversion_id: 6744426 });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleGtagCapture = useCallback(async () => {
    try {
      if (window.gtag) {
        const callback = () => {};
        window.gtag("event", "conversion", {
          send_to: `${
            process.env.GATSBY_LANG === "en"
              ? "AW-10865368368"
              : process.env.GATSBY_LANG === "sv"
              ? "G-PP1G19T52Y"
              : ""
          }/1vEjCPmRrNwDELC6gb0o`,
          event_callback: callback,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleFormSubmit = async (e) => {
    if (entries.email === "") {
      count = count + 1;
      setEmailErr("*Enter Your Business Email");
    }
    if (entries.email !== "") {
      const mailformat = /^[\p{L}\d+.-]+@[\p{L}\d-]+\.\p{L}+$/u;

      if (entries.email.match(mailformat)) {
        setEmailErr("");
      } else {
        count = count + 1;
        setEmailErr("*Enter Your Valid Business Email");
      }
    }
    if (entries.phone !== "") {
      setNumberErr("");
    }
    if (entries.fullname !== "") {
      var regName = /^[\p{L}\s]+$/u;
      if (!regName.test(entries.fullname)) {
        count = count + 1;
        setNameErr("*Enter your Valid full name");
      } else {
        setNameErr("");
      }
    }

    if (entries.phone === "" || entries.phone.length < 8) {
      count = count + 1;
      setNumberErr("*Enter Correct Phone Number");
    }
    if (entries.fullname === "") {
      count = count + 1;
      setNameErr("*Enter Your Full Name");
    }

    if (entries.organization === "") {
      count = count + 1;
      setOrgErr("*Enter Your Organization Name");
    } else if (entries.organization !== "") {
      setOrgErr("");
    }

    const token = await processReCaptcha();

    if (!token) {
      console.log("reCAPTCHA validation failed");
      return;
    }
    console.log("reCAPTCHA validation successful");
    if (count === 0) {
      const source =
        process.env.GATSBY_LANG === "en"
          ? "opsiocloud.com"
          : process.env.GATSBY_LANG === "sv"
          ? "opsio.se"
          : "opsio.in";
      setNumberErr();
      setNameErr();
      setEmailErr();
      setOrgErr();
      var formdata = new FormData();
      formdata.append(
        "data",
        JSON.stringify({
          ...entries,
          contactMethod: data.contactDetail[entries.contactMethod],
          token,
          source,
          marketingConsent,
        })
      );
      formdata.append("files.file", imgUpload);
      let requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      try {
        setSubmitLoading(true);
        handleLinkedInCapture();
        handleGtagCapture();
        if (data.careersform) {
          await fetch(
            `${process.env.GATSBY_API_URL}/api/email-careers`,
            requestOptions
          );
        } else {
          const data = await fetch(
            `${process.env.GATSBY_API_URL}/api/contactforms`,
            requestOptions
          );
          await axios.post(`${process.env.GATSBY_API_URL}/api/email-contact`, {
            ...entries,
            source,
          });
        }
        Swal?.fire({
          title: data.contactLayout.alertTitle,
          text: data.contactLayout.alertText,
          icon: "success",
          confirmButtonColor: "#0CCB95",
        });
      } catch (e) {
        setIsVisible(false);
      } finally {
        setSubmitLoading(false);
      }

      setEntries({
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        message: "",
        phone: "",
        contactMethod: "",
      });
      setIsFilePicked(false);
    }
    e.preventDefault();
  };

  useEffect(() => {
    axios.get("https://api.db-ip.com/v2/free/self").then((ipData) => {
      if (ipData?.data?.countryCode) {
        setCountry(ipData.data.countryCode.toLowerCase());
      }
    });
  }, [country]);

  return (
    <div
      className="blogContactForm"
      style={{ backgroundColor: "#17263F" }}
      id="contact_form"
    >
      <div className="title">{layoutData.getInTouch}</div>
      <div className="subTitle">{layoutData.connectUs}</div>
      <div className="description">{layoutData.whatYouThink}</div>
      <div className="contactForm">
        <div className="row pt-5">
          <div>
            <h6 className="mb-0 ms-3 taperzoid font-size-16 line-height-20 font-weight-700">
              {data.contactLayout.information}
            </h6>
          </div>
          <div className="formContainer">
            <div className="text-center contact-details-color">
              <div className="contact_from">
                <div
                  className="block"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="contact-input">
                    <input
                      type="text"
                      className="contactFormInput "
                      aria-label="First Name"
                      value={entries.firstName}
                      placeholder={data.firstName}
                      onChange={(e) =>
                        setEntries({
                          ...entries,
                          firstName: e.target.value,
                        })
                      }
                    />
                    <p className="input-error">{nameErr}</p>
                  </div>
                  <div className="number-input">
                    <input
                      type="text"
                      className="contactFormInput "
                      value={entries.lastName}
                      aria-label="Last Name"
                      placeholder={data.lastName}
                      onChange={(e) =>
                        setEntries({ ...entries, lastName: e.target.value })
                      }
                    />
                    <p className="input-error">{nameErr}</p>
                  </div>
                </div>

                <div
                  className="block"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="contact-input">
                    <input
                      type="email"
                      className="contactFormInput "
                      aria-label="Email Address"
                      value={entries.email}
                      placeholder={data.email}
                      onChange={(e) =>
                        setEntries({ ...entries, email: e.target.value })
                      }
                    />
                    <p className="input-error">{emailErr}</p>
                  </div>
                  <div className="number-input">
                    <PhoneInput
                      country={country}
                      value={entries.phone}
                      placeholder={data.phonenumber}
                      onChange={(e) => setEntries({ ...entries, phone: e })}
                      containerClass="intTel"
                      inputClass="intTelInput"
                    />
                    <p className="input-error">{numberErr}</p>
                  </div>
                </div>
                {!data.careersform && (
                  <div
                    className="block"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="contact-input">
                      <input
                        type="text"
                        className="contactFormInput "
                        aria-label="Organization Name"
                        value={entries.organization}
                        placeholder={data.orgname}
                        onChange={(e) =>
                          setEntries({
                            ...entries,
                            organization: e.target.value,
                          })
                        }
                      />
                      <br />
                      <p className="input-error">{orgErr}</p>
                    </div>
                    <div className="number-input">
                      <label
                        htmlFor="#select-contact-method"
                        className="d-none"
                      />
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        value={entries.contactMethod}
                        onChange={(e) =>
                          setEntries({
                            ...entries,
                            contactMethod: e.target.value,
                          })
                        }
                        id="select-contact-method"
                      >
                        <option value="contact">
                          {data.contactLayout.contact}
                        </option>
                        <option value="sales">
                          {data.contactLayout.sales}
                        </option>
                        {locale !== "en-IN" && (
                          <option value="support">
                            {data.contactLayout.support}
                          </option>
                        )}
                        <option value="finance">
                          {data.contactLayout.humanResources}
                        </option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="block">
                  <textarea
                    className="contactFormText  text-white"
                    value={entries.message}
                    placeholder={data.message}
                    onChange={(e) =>
                      setEntries({ ...entries, message: e.target.value })
                    }
                  />
                </div>
                <div className="block">
                  {isFilePicked ? (
                    <div className="d-flex justify-content-between text-white">
                      <p>{`Selected File :${JSON.stringify(
                        imgUpload.name
                      )}`}</p>
                      <span
                        title="Delete File"
                        className="file-delete-btn"
                        onClick={deleteUploadedFile}
                      >
                        &times;
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <label className="contactFormInput input-file open-sans">
                    <img className="mx-2" src={attachIcon} alt="attach" />
                    {data.file}
                    <input
                      type="file"
                      aria-label="Upload File"
                      placeholder={data.file}
                      onChange={onChangeFile}
                    />
                  </label>
                </div>

                <div className="my-3">
                  <div className="desktopViewCheckbox">
                    <input
                      type="checkbox"
                      aria-label="Accept Policy"
                      onChange={() => setCheckboxStatus(!checkboxStatus)}
                      checked={checkboxStatus}
                      id="policy"
                      className="m-0"
                    />
                    <label
                      htmlFor="#policy"
                      className="text-start white-color link-text "
                      dangerouslySetInnerHTML={{
                        __html: data.checkboxText1,
                      }}
                    />
                  </div>
                  <div className="desktopViewCheckbox">
                    <input
                      type="checkbox"
                      aria-label="Accept Marketing Consent"
                      checked={marketingConsent === "Yes" ? true : false}
                      onChange={(e) =>
                        setMarketingConsent(e.target.checked ? "Yes" : "No")
                      }
                      id="accept"
                      className="m-0"
                    />
                    <label
                      htmlFor="#accept"
                      className="text-start mt-3 white-color link-text "
                    >
                      {data.checkboxText2}
                    </label>
                  </div>
                </div>
                {submitLoading ? (
                  <button className="contactFormBtn disabled">
                    <ClipLoader
                      color={"#fff"}
                      loading={true}
                      size={35}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </button>
                ) : (
                  <button
                    disabled={!checkboxStatus}
                    className={`contactFormBtn ${
                      !checkboxStatus ? "disabled" : ""
                    }`}
                    onClick={handleFormSubmit}
                  >
                    {data.btn}
                  </button>
                )}
              </div>
            </div>
            <div className="authorContainer">
              <img src={AuthorImg} alt="AuthorImg" />
              <p>{layoutData.hello}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormInBlog;
