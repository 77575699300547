import React, { useState } from "react";

import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { AmpXImage } from "../customs/ampImage";

const Faq = ({ title, faqs, isMobileView, isAMP }) => {
  // return null
  const [activeIndex, setActiveIndex] = useState(0);

  const mainEntity = () => {
    return faqs?.map((faq) => {
      return {
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer.data.answer,
        },
      };
    });
  };
  if (!title || !faqs) return null;
  return (
    <>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: `{
          "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": ${JSON.stringify(mainEntity())}
        }
        `,
          },
        ]}
      />

      <div
        className={` ${
          isMobileView ? "background-color" : "background-color py-2"
        }`}
      >
        <div className="global-margin">
          <div className="margin-top-botton">
            <div className="global-services-title roboto global-title-color mb-5">
              {title}
            </div>
            <div
              className={` ${
                isMobileView
                  ? "d-flex flex-column"
                  : "d-flex justify-content-between "
              }`}
            >
              <div
                className={`${
                  isMobileView ? "col-12 text-center" : "col-md-5 p-1"
                }`}
              >
                {faqs?.map((faq, index) => (
                  <div
                    key={faq.id}
                    className="d-flex faq-dev my-4 pt-5 pb-3 ps-5 justify-content-between"
                  >
                    {/* <h1 className={`devops-faqs-ques   text-start mb-0 ${activeIndex === index ? 'global-title-color' : 'text-light'}`}> */}
                    <div
                      className={`${
                        activeIndex === index
                          ? "global-title-color"
                          : "text-light"
                      } ${
                        isMobileView
                          ? "font-size-16 line-height-25 font-weight-400  text-start mb-0 "
                          : "devops-faqs-ques  text-start mb-0 "
                      }`}
                    >
                      {faq.question}
                    </div>
                    <AmpXImage
                      className="pointer text-end d-flex align-items-center mt-2"
                      onClick={() => setActiveIndex(index)}
                      src={activeIndex === index ? minus : plus}
                      alt={activeIndex === index ? "Minus" : "Plus"}
                    />
                  </div>
                ))}
              </div>
              {faqs?.map((faq, index) => (
                <>
                  {index === activeIndex && (
                    <div
                      className={` ${
                        isMobileView
                          ? "col-12 backround-card p-4"
                          : "col-6 p-4 pt-0"
                      }`}
                    >
                      <div className="taperzoid mx-5 py-3"></div>
                      <div
                        className={` ${
                          isMobileView ? "backround-card" : "backround-card p-5"
                        }`}
                      >
                        <div
                          className={` ${
                            isMobileView
                              ? "global-title-color devops-faqs-ques text-start pb-2 "
                              : "global-title-color devops-faqs-ques text-start pb-5 "
                          }`}
                        >
                          {faq.question}
                        </div>
                        <div
                          className={` ${
                            isMobileView
                              ? "text-light font-size-18 line-height-25 font-weight-400  max-height-1000"
                              : "text-light global-services-desc  max-height-1000"
                          }`}
                        >
                          <ReactMarkdown>
                            {faq.answer.data.answer}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
