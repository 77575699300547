import React, { memo } from "react";

export const BlogList = memo(({ listTitles, recentBlogs, popularBlogs }) => {
  return (
    <div className="blogListContainer">
      <div>
        <div className="listTitle">{listTitles.recent}</div>
        {recentBlogs.map((blog) => (
          <a
            key={blog.node.title}
            className="listItem"
            href={`/resource/blog/${blog.node.slug}`}
          >
            {blog.node.heading1}
          </a>
        ))}
      </div>
      <div>
        <div className="listTitle">{listTitles.popular}</div>
        {popularBlogs.map((blog) => (
          <a
            key={blog.node.title}
            className="listItem"
            href={`/resource/blog/${blog.node.slug}`}
          >
            {blog.node.heading1}
          </a>
        ))}
      </div>
    </div>
  );
});
