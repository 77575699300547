import React from "react";
import shareIcon from "../../../src/images/shareIcon.svg";
import linkdinIcon from "../../../src/images/linkdinIcon.svg";
import twitterIcon from "../../../src/images/twitterIcon.svg";
import facebookIcon from "../../../src/images/facebookIcon.svg";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  All,
} from "react-share";

const ShareButtons = ({ title, url, twitterHandle, tags, color = "light" }) => {
  return (
    <div className="blogSocialIconsWrapper">
      <div>
        {color === "light" ? (
          <img src={shareIcon} alt="shareIcon" />
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.013 0.667969C12.1819 0.667969 10.6797 2.17023 10.6797 4.0013C10.6797 4.06255 10.6943 4.11987 10.6976 4.18034L6.38444 6.67057C5.77841 6.05409 4.93889 5.66797 4.01302 5.66797C2.18194 5.66797 0.679688 7.17022 0.679688 9.0013C0.679688 10.8324 2.18194 12.3346 4.01302 12.3346C4.93889 12.3346 5.77841 11.9485 6.38444 11.332L10.6976 13.8223C10.6943 13.8827 10.6797 13.9401 10.6797 14.0013C10.6797 15.8324 12.1819 17.3346 14.013 17.3346C15.8441 17.3346 17.3464 15.8324 17.3464 14.0013C17.3464 12.1702 15.8441 10.668 14.013 10.668C12.8455 10.668 11.816 11.2823 11.22 12.1995L7.21126 9.88672C7.29072 9.60286 7.34635 9.30937 7.34635 9.0013C7.34635 8.69324 7.29072 8.39975 7.21126 8.11588L11.22 5.80143C11.8159 6.71937 12.845 7.33463 14.013 7.33463C15.8441 7.33463 17.3464 5.83238 17.3464 4.0013C17.3464 2.17023 15.8441 0.667969 14.013 0.667969Z"
              fill="#455165"
            />
          </svg>
        )}
      </div>
      <FacebookShareButton url={url}>
        {color === "light" ? (
          <img src={facebookIcon} alt="facebookIcon" />
        ) : (
          <svg
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0988 11.0647L10.6038 7.77182H7.44445V5.63495C7.44445 4.73407 7.88577 3.85597 9.30087 3.85597H10.7373V1.05258C10.7373 1.05258 9.43369 0.830078 8.18738 0.830078C5.58526 0.830078 3.88459 2.4071 3.88459 5.26212V7.77182H0.992188V11.0647H3.88459V19.025C4.46456 19.116 5.059 19.1634 5.66454 19.1634C6.27007 19.1634 6.86448 19.116 7.44445 19.025V11.0647H10.0988Z"
              fill="#455165"
            />
          </svg>
        )}
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        {color === "light" ? (
          <img src={twitterIcon} alt="twitterIcon" />
        ) : (
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.891037 5.64988H4.4629V16.3333H0.891037V5.64988ZM2.70088 0.5C1.47838 0.5 0.679688 1.29769 0.679688 2.34469C0.679688 3.37016 1.455 4.19104 2.65413 4.19104H2.67691C3.92278 4.19104 4.69866 3.37012 4.69866 2.34469C4.67528 1.29769 3.92282 0.5 2.70088 0.5ZM13.2328 5.39892C11.3368 5.39892 10.4874 6.43543 10.0136 7.16233V5.64988H6.44064C6.48794 6.65212 6.44064 16.3333 6.44064 16.3333H10.0136V10.3669C10.0136 10.0474 10.0369 9.72902 10.131 9.50011C10.3896 8.86219 10.9775 8.2016 11.9636 8.2016C13.2573 8.2016 13.774 9.18174 13.774 10.6173V16.3333H17.3464V10.2072C17.3464 6.92576 15.5844 5.39892 13.2328 5.39892Z"
              fill="#455165"
            />
          </svg>
        )}
      </TwitterShareButton>

      <LinkedinShareButton url={url}>
        {color === "light" ? (
          <img src={linkdinIcon} alt="linkdinIcon" />
        ) : (
          <svg
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16259 14.1667C12.7418 14.1667 16.3403 8.71587 16.3403 3.98893C16.3403 3.83413 16.3403 3.68 16.3299 3.52656C17.0299 3.02017 17.6343 2.39323 18.1145 1.675C17.4617 1.96428 16.7691 2.15395 16.06 2.23779C16.8065 1.79088 17.3657 1.08743 17.6326 0.259275C16.9305 0.675905 16.1622 0.969573 15.3612 1.12754C14.6848 0.408322 13.7402 0 12.753 0C10.7888 0 9.17258 1.61626 9.17258 3.5804C9.17258 3.85289 9.20371 4.12452 9.26528 4.38993C6.39064 4.24585 3.70861 2.88614 1.89314 0.652614C0.949076 2.2778 1.43756 4.38394 3.00059 5.42764C2.43141 5.41078 1.87445 5.25721 1.37708 4.97992V5.02524C1.37756 6.72153 2.58413 8.19589 4.24684 8.53175C3.72026 8.67535 3.16762 8.69633 2.63171 8.59311C3.09914 10.0466 4.44707 11.0486 5.9736 11.0772C4.7072 12.0725 3.14191 12.6131 1.5312 12.6115C1.24659 12.611 0.962254 12.5937 0.679688 12.5599C2.31536 13.6095 4.21909 14.1665 6.16259 14.1639"
              fill="#455165"
            />
          </svg>
        )}
      </LinkedinShareButton>
    </div>
  );
};
export default ShareButtons;
