import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Moment from "react-moment";
import BlogImage from "../BlogImage";
function Blog({ data, locale, slugPrefix, isMobileView, isAMP, blog }) {
  const { NoImage } = useStaticQuery(
    graphql`
      query {
        NoImage: file(relativePath: { eq: "no-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <div className="relatedBlogs">
      <div className="title">{blog.relatedBlogs}</div>
      <div className="blogsWrapper">
        {data.map((item, index) => {
          return (
            <Link to={`/${slugPrefix}${item.node.slug}/`} key={index}>
              <div className="blogCard" key={index}>
                <BlogImage data={item.node} isAMP={isAMP} />
                <div className="blogTitle">{item.node.title}</div>
                <div className="blogSubTitle">{item.node.heading1}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.node?.preview?.data?.preview,
                  }}
                  className="blogDescription"
                ></div>
                <div className="blogDate">
                  <div className="date">
                    <Moment format="MMMM Do YYYY">{item.node.date}</Moment>
                  </div>
                  <div className="divider" />
                  <div className="time">{item.node.time}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
